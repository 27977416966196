
import { defineComponent, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { Actions } from "@/store/enums/StoreEnums";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import * as Yup from "yup";
import { useI18n } from "vue-i18n";
import { appGlobalConfig } from "@/core/helpers/config";
import store from "@/store";

export default defineComponent({
  name: "sign-in",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  data() {
    return {
      signInLoader: false,
    };
  },
  methods: {
    //Form submit function
    onSubmitLogin: function (values) {
      const submitButton = ref<HTMLElement | null>(null);
      if (submitButton.value && document.getElementById("kt_sign_in_submit")) {
        // Activate indicator
        submitButton.value.setAttribute("data-kt-indicator", "on");
      }
      this.signInLoader = true;

      // Send login request
      store
        .dispatch(Actions.LOGIN, values)
        .then(() => {
          // Go to page after successfully login
          window.location.href = window.location.origin + "/#/home";
        })
        .catch(() => {
          Swal.fire({
            text: this.translate("app.invalid_credentials"),
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: this.translate("app.try_again"),
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
        })
        .finally(() => {
          if (
            submitButton.value &&
            document.getElementById("kt_sign_in_submit")
          ) {
            // Activate indicator
            //Deactivate indicator
            submitButton.value?.removeAttribute("data-kt-indicator");
          }
          this.signInLoader = false;
        });
    },
    translate: function (text: string) {
      if (this.te(text)) {
        return this.t(text);
      } else {
        return text;
      }
    },
  },
  setup() {
    //Create form validation object
    const { t, te } = useI18n();

    const login = Yup.object().shape({
      username: Yup.string().required().label("Username"),
      password: Yup.string().min(4).required().label("Password"),
    });

    return {
      login,
      t,
      te,
      appGlobalConfig,
    };
  },
});
